<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-company-holiday-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "Holidays.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="holidaysData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="Day" class="form-label required">{{
                $t("Holidays.Day", {}, { locale: this.$store.state.activeLang })
              }}</label>
              <DatePicker
                type="date"
                id="Day"
                :value="holidaysData.day"
                :isRequired="true"
                :displayFormat="$store.getters._dateFormat"
                @valueChanged="onValueChanged"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsHalf" class="form-label">{{
                  $t(
                    "Holidays.IsHalf",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsHalf"
                  v-model="holidaysData.isHalf"
                />
              </div>
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="holidaysData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "CompanyHolidayEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      holidaysData: {},
    };
  },
  methods: {
    onValueChanged(date) {
      this.holidaysData.day = date;
      if (!String.isNullOrWhiteSpace(date)) {
        this.holidaysData.day = this.$moment(date).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }
    },
    getHolidayDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CompanyHolidayGet?id={0}",
            this.$route.params.holidayId
          )
        )
        .then((response) => {
          this.holidaysData = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-company-holiday-edit");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-CompanyHolidayEdit", { ...this.holidaysData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/Holiday/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getHolidayDetail();
  },
};
</script>
